<template>
  <div class="content_header">
    <h2 class="content_header__title">
      <slot name="title"></slot>
    </h2>
    <div class="content_header__action">
      <slot name="action">
        <v-btn v-if="$slots.action" outlined>
          <slot name="action"></slot>
        </v-btn>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentHeader'
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables/_colors.scss";

.content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;

  &__title {
    font-size: 18px;
    color: $text-primary;
    margin: 0;

    @media (max-width: $tablet) {
      font-size: 18px;
    }

    @media (max-width: $mobile) {
      font-size: 18px;
    }
  }

  &__action {
    // Vuetify button override styles
    ::v-deep .v-btn {
      &.v-btn--outlined {
        border: 1px solid $outline-button-border;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 120px;
        height: 32px;
        padding-left: 10px;
        padding-right: 20px;
        color: $text-primary;
        font-size: 16px;
        position: relative;
        transition: 0.25s;
        margin-top: -6px;
        text-transform: none;

        // Mobile-specific adjustments
        @media (max-width: $mobile) {
          border: none;
          font-size: 18px;
          height: 27px;
          margin-top: 0;
        }

        // Hover state
        &:hover {
          background-color: $button-hover-bg;
        }

        // Right arrow icon using SVG background
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 100%;
          top: 0;
          right: 0;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23dcdcdc' d='M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.41z'/%3E%3C/svg%3E");
          background-size: 16px;
          background-position: left center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
</style>
