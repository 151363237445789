<template>
    <div class="private-profile-layout">
        <topWelcomeBanner style="z-index: 1;"></topWelcomeBanner>
        <slot></slot>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";
export default {
    name: "userProfileLayout",
    components: {
        topWelcomeBanner
    }
}
</script>

<style lang="scss">

</style>