<template>
    <div>
        <div class="private_user_photo">
            <!-- Loader overlay just for the image -->
            <div v-if="isUpdating" class="avatar-update-overlay">
                <v-progress-circular :size="50" :width="5" color="#20C4F5" indeterminate></v-progress-circular>
            </div>

            <v-img :src="`${avatar.small}?cache=${avatar.key}`"
                   v-bind:srcset="avatar.srcset ? `${avatar.small}?cache=${avatar.key} 1x, ${avatar.srcset}?cache=${avatar.key} 2x` : null"
                   loading="lazy"
                   @load="avatarLoaded"
                   :class="{'default-image': isImageDefault, 'show': avatar.loaded}"
                   @click="$refs['modal-image'].show()"
                   alt="My profile avatar">
            </v-img>

            <button v-if="showChangeButton" 
                    type="button" 
                    class="change_ava" 
                    @click="changeAva">
                <span class="material-icons">photo_camera</span>
            </button>

            <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">

            <PhotoClipper 
                v-if="clipperDialog"
                :show="clipperDialog"
                :image="clipperAvatar"
                @close="closeClipperDialog"
                @upload="changeAva"
                @save="handleClipperSave"
            />
        </div>

        <!-- Modal Image rendered at root level -->
        <teleport to="body">
            <modalImage ref="modal-image" :image="`${avatar.original}?cache=${avatar.key}`"/>
        </teleport>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {baseUrlToBlob} from "@/utils/baseUrlToBlob";
import PhotoClipper from "./PhotoClipper.vue";

const ModalImage = () => import("@/components/public/modalImage");

export default {
    name: "privateProfileAvatar",
    components: {
        ModalImage,
        PhotoClipper
    },
    props: {
        showChangeButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            isUpdating: false,
            isImageDefault: false,
            avatar: {
                original: '',
                small: '',
                srcset: '',
                loaded: false,
                key: new Date().getTime(),
            },
            uploadedFile: '',
            clipperAvatar: '',
            changeAvatarLoader: false,
            clipperDialog: false,
        }
    },
    watch: {
        PROFILE: {
            handler() {
                this.getUserAvatar();
                this.avatar.loaded = false;
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(['PROFILE', 'PROFILE_LOADER', 'CHANGE_USER_AVA', 'FILE_URL', 'userID', 'PROFILE_LOGO'])
    },
    mounted() {
        this.getUserAvatar();
    },
    methods: {
        ...mapActions(['GET_PROFILE', 'ADD_FILE', 'CHANGE_USER_DATA', 'GET_USER']),

        getUserAvatar() {
            this.changeAvatarLoader = true;

            this.avatar.small = require('@/assets/image/svg/default_avatar.svg');
            if (this.PROFILE_LOGO && this.PROFILE_LOGO['240x270'] ) {
                this.avatar.small = this.PROFILE_LOGO['240x270'];
                this.avatar.srcset = this.PROFILE_LOGO['240x270@2x']
            }

            this.avatar.original = (this.PROFILE_LOGO && this.PROFILE_LOGO['960x1080']) ? this.PROFILE_LOGO['960x1080'] : require('@/assets/image/svg/default_avatar.svg');

            this.isImageDefault = (this.PROFILE && !this.PROFILE.photo);
            this.changeAvatarLoader = false;
            this.avatar.key = new Date().getTime();
        },
        avatarLoaded() {
            this.avatar.loaded = true;
            if (this.isUpdating) {
                this.finalizeUpdate();
            }
        },

        async photoUpload() {
            this.uploadedFile = this.$refs.uploadedFile.files[0];
            this.clipperDialog = true;

            // pre load image url
            const fileReader = new FileReader();
            await fileReader.addEventListener('load', () => {
                this.clipperAvatar = fileReader.result;
            });
            fileReader.readAsDataURL(this.uploadedFile);
        },

        changeAva() {
            let accessToken = localStorage.getItem('accessToken');
            if (accessToken){
                this.$refs.uploadedFile.click();
                if (this.uploadedFile) {
                    this.clipperDialog = true;
                }
            } else {
                console.log('changeAva -redirect login');
                this.$router.push({name: 'login'});
            }
        },

        handleClipperSave(dataURL) {
            this.isUpdating = true;
            this.changeAvatarLoader = true;
            this.uploadedFile = baseUrlToBlob(dataURL);
            this.clipperDialog = false;

            let formData = new FormData();
            formData.append('file', this.uploadedFile);
            formData.append('type', 'user');

            const userData = {
                id: this.userID,
            }
            this.ADD_FILE(formData)
                .then(() => {
                    userData.photo = this.FILE_URL;
                })
                .catch(err => console.log(`ADD_FILE (private profile), ${err}`))
                .finally(() => {
                    if (userData.photo) {
                        this.CHANGE_USER_DATA(userData)
                            .then(() => {
                                this.$store.commit('SET_CHANGE_USER_AVA', true);
                                // Refresh user data to update profile background
                                this.GET_USER(this.userID)
                                    .then(() => {
                                        this.$nextTick(() => {
                                            this.getUserAvatar();
                                            // Emit event to parent to refresh background
                                            this.$emit('avatar-updated');
                                        });
                                    });
                            })
                            .catch(err => {
                                console.log(`saveUserData, ${err}`);
                                this.isUpdating = false;
                            })
                    } else {
                        this.isUpdating = false;
                    }
                })
        },

        finalizeUpdate() {
            // Wait a moment to ensure all updates are processed
            setTimeout(() => {
                this.isUpdating = false;
                this.changeAvatarLoader = false;
            }, 1000);
        },

        closeClipperDialog() {
            this.clipperDialog = false;
        }
    }
}
</script>

<style lang="scss">
.private_user_photo {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .v-image {
        opacity: 0;
        transition: 0.3s;
        width: 240px;
        height: 270px;
        border-radius: 8px;
        overflow: hidden;

        &.show {
            opacity: 1;
        }
        
        .v-responsive__content {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
        }
        
        .v-image__image {
            background-size: cover !important;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
        }
    }

    // New avatar update overlay styles
    .avatar-update-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(3, 16, 32, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        border-radius: 8px;
    }
}
</style>
